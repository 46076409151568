import React from 'react';
import Header from '../Header/header'

import Notification from '../containers/notification'

import DrawingCanvas from '../DrawingCanvas/drawingCanvas';

import { Link, Redirect } from 'react-router-dom'
import StepsNavigator from '../components/stepsNavigator'
import FooterNavigator from '../components/footerNavigator'

import { ChevronUpIcon, ChevronDownIcon } from '@heroicons/react/solid'

const steps = [
  { id: '01', name: 'Upload Images', href: '#', status: 'complete' },
  { id: '02', name: 'Review Paragraphs', href: '/paragraph-review/', status: 'current' },
  { id: '03', name: 'Review Text', href: '/text-line-review/', status: 'upcoming' },
  { id: '04', name: 'Export Text', href: '#', status: 'upcoming' }
]

/*
project_meta 

project_meta_id
group_id 
-- model_id 
project_id
key: model_in_use, model_status, nr_new_labels, nr_total_labels, nr_new_reviewed_images
value: 
  for model_in_use: model_id 
*/

/*
  model_training, model_staging, model_prod, model_archived
  
  model_id

  for each annotation: in which models was it used?
  for each model: what annotations did it use? 

  model_training_labels 
  model_label_id 
  annotation_id
  model_id

  new labels: select all annotations that do not appear in model_training_labels 

*/

/*

*/

const ParagraphReview = ({ 
  key,
  image, 
  notebook, 
  onPrevClick, 
  onSkipClick, 
  onSubmitClick, 
  saveError, 
  paragraphSaveSuccess, 
  nextParagraphReviewImage,
  prevParagraphReviewImage,
  maxImgWidth, 
  reviewStatus, 
  notebookImageOutline, 
  outlineOffset, 
  outlineCount, 
  onPrevOutlinePageClick, 
  onNextOutlinePageClick, 
  currentProjectId, 
  lines, 
  onPolygonDeleteClick, 
  formatFilename
}) => (
  <div className="bg-gray-100 min-h-screen relative" key={key}>
    <div className="fixed left-0 top-0 w-full z-10">
      <Header disableNotebookButton={true}/>

      {/* <p>Status: Not Reviewed | Predicted by Rosetta | Reviewed by human</p>
      <p>Model status: no training in progress | Training in progress, approx 5 hours remaining. | Training finished X hours ago. Prediction in progress.</p>
      <p>Model in use: no model trained yet | June 12th, 2023</p>
      <p>New labels available: 200</p>
      <p>New Reviewed images: 50</p>
      <p>Total labels available for training: 300</p>
      <p>Total images avaialble for training: 100</p> */}


      {/* <StepsNavigator currentProjectId={currentProjectId} notebookIdentifier={notebook.identifier} steps={steps}/> */}
    </div>

    {/* TODO: If drawed box originates from ML model, clearly show that. */}
    {/* TODO: ability to delete a drawed box from the image */}
    <div>
      <div className="max-w-7xl mx-auto" style={{paddingTop: 180}}>
        <div className="px-4 flex sm:px-0">
          <div className="w-1/8">
            <div className="bg-white border border-gray-8 rounded-md m-8 relative overflow-auto">
              <center>
                {notebookImageOutline.hasPrevPage &&
                  <span>
                    <button
                      onClick={onPrevOutlinePageClick}
                      className="relative inline-flex items-center px-2 py-2 rounded-t-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 cursor-pointer"
                    >
                      <span className="sr-only">Previous</span>
                      <ChevronUpIcon className="h-5 w-5" aria-hidden="true" />
                    </button><br/>
                  </span>
                }
                <small><strong>{outlineOffset * outlineCount} - {(outlineOffset + 1) * outlineCount}</strong></small><br/>
                <small>of {notebookImageOutline.totalNotebookImageAmount}</small>
              </center>
              {notebookImageOutline.outline.map((imageOutline, index) =>
                <Link to={`/${currentProjectId}/paragraph-review/${notebook.identifier}/${imageOutline.identifier}`} className="relative">
                  <div 
                    key={index} 
                    className={`object-cover 
                      ${imageOutline.identifier === image.identifier ? 'bg-white' : 'bg-gray-100'}
                      ${imageOutline.identifier === image.identifier ? 'opacity-100' : 'opacity-75'}
                      hover:opacity-100 p-4 hover:bg-white
                      focus-within:ring-offset-2 focus-within:ring-indigo-500
                    `}
                  >
                    <div className="relative">
                      <img src={window.location.protocol + '//' + window.location.hostname + ':' + window.location.port + '/' + imageOutline.thumb_image_url} 
                        style={{maxWidth: 70}} 
                        className="rounded-md"
                        alt=""
                      />
                      {!imageOutline.checked_for_inheritance && 
                        <div className="relative">
                          <div className="inline-flex items-center px-1 py-1 rounded-full text-xs font-medium bg-indigo-100 text-indigo-800 absolute" style={{top: '-22px', right: '6px'}}>
                            <svg className="-ml-1.5 mr-1.5 h-2 w-2 text-indigo-400" fill="currentColor" viewBox="0 0 8 8" style={{ marginLeft: 'auto', marginRight: 'auto'}}>
                              <circle cx={4} cy={4} r={3} />
                            </svg>
                          </div>
                        </div>
                      }

                      {imageOutline.checked_for_inheritance &&
                        <div className="relative">
                          <div className="items-center px-1 py-1 rounded-full text-xs font-medium bg-green-100 text-green-800 absolute" style={{top: '-22px', right: '6px'}}>
                            <svg className="-ml-1.5 mr-1.5 h-2 w-2 text-green-400" fill="currentColor" viewBox="0 0 8 8" style={{ marginLeft: 'auto', marginRight: 'auto'}}>
                              <circle cx={4} cy={4} r={3} />
                            </svg>
                          </div>
                        </div>
                      }
                    </div>

                    <p className="text-sm text-center mt-2">
                      <span className={`${imageOutline.identifier === image.identifier ? "bg-indigo-700 p-1 rounded-lg text-white" : ""}`}>{formatFilename(imageOutline.filename)}</span>
                    </p>
                  </div>
                </Link>
              )}
              <center>
                {notebookImageOutline.hasNextPage &&
                  <span>
                    <button
                      onClick={onNextOutlinePageClick}
                      className="relative inline-flex items-center px-2 py-2 rounded-b-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 cursor-pointer"
                    >
                      <span className="sr-only">Next</span>
                      <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
                    </button><br/>
                  </span>
                }
                <small><strong>{outlineOffset * outlineCount} - {(outlineOffset + 1) * outlineCount}</strong></small><br/>
                <small>of {notebookImageOutline.totalNotebookImageAmount}</small>
              </center>
            </div>
          </div>

          <div className="w-6/8 px-4 sm:px-6 lg:px-8">
            <div className="focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500 group block w-full rounded-lg">
              <div className="group-hover:opacity-100 object-contain py-8 mb-16">
                {image &&
                  <DrawingCanvas 
                    backgroundImageUrl={image.image_url} 
                    imgOriginalWidth={image.img_width}
                    maxImgWidth={maxImgWidth}
                  />
                }
              </div>
            </div>
          </div>

          <div className="w-1/8">
            {lines.map((line, index) => 
              <p>no: {index} {line.identifier} <button onClick={() => onPolygonDeleteClick(line.identifier)}>[x]</button></p>
            )}
          </div>

        </div>
      
      </div>
      
    </div>
    
    <FooterNavigator
        prevReviewImageId={prevParagraphReviewImage ? prevParagraphReviewImage : false}
        notebookId={notebook.identifier}
        reviewStatus={reviewStatus}
        onSkipClick={onSkipClick}
        onSubmitClick={onSubmitClick}
        onPrevClick={onPrevClick}
        nextReviewImageId={nextParagraphReviewImage ? nextParagraphReviewImage : false}
        nextReviewBlockLabel='text review'
        reviewBlock='paragraph-review'
        nextReviewBlock='text-line-review'
        currentProjectId={currentProjectId}
      />
    
    {saveError &&
      <Notification/>
    }

    {paragraphSaveSuccess &&
      <Redirect to={`/${currentProjectId}/paragraph-review/${notebook.identifier}/${nextParagraphReviewImage}`}/>
    }
  </div>
)

export default ParagraphReview