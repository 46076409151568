import _ from 'lodash';

export const notebooks = (state = [], action) => {
  switch (action.type) {
    case 'SET_NOTEBOOKS': 
      return action.notebooks
    default:
      return state
  }
}

export const notebookSingle = (state = {}, action) => {
  switch (action.type) {
    case 'SET_SINGLE_NOTEBOOK':
      return action.notebook
    default:
      return state
  }
}

export const paragraphReviewImage = (state = {}, action) => {
  switch (action.type) {
    case 'SET_PARAGRAPH_REVIEW_IMAGE':
      return action.notebookImage
    default:
      return state
  }
}

export const paragraphAnnotations = (state = [], action) => {
  switch (action.type) {
    case 'SET_PARAGRAPH_ANNOTATIONS':
      const annotations = _.map(action.annotations, function(o) {
        return { ...o, isActive: false }
      })
      return annotations
    case 'DELETE_PARAGRAPH_ANNOTATION':
      const newAnnotations =  _.filter(action.annotations, function(o) { 
        return o.identifier !== action.identifier; 
     });

     return newAnnotations
    case 'SET_ANNOTATION_MOUSE_OVER':
      const annotationsMouseOver = _.map(action.annotations, function(o) {
        if(o.identifier === action.identifier) {
          o.mouseover = true;
        }

        return o;
      })

      return annotationsMouseOver
    case 'SET_ANNOTATION_MOUSE_OUT': 
      const annotationsMouseOut = _.map(action.annotations, function(o) {
        if(o.identifier === action.identifier) {
          o.mouseover = false;
        }

        return o;
      })

      return annotationsMouseOut
    default:
      return state
  }
}

export const paragraphSaveError = (state = false, action) => { 
  switch(action.type) {
    case 'SAVE_PARAGRAPH_ERROR': 
      return action.value
    default: 
      return state
  }
}

export const paragraphSaveSuccess = (state = false, action) => {
  switch(action.type) {
    case 'SAVE_PARAGRAPH_SUCCESS': 
      return action.value
    case 'SAVE_PARAGRAPH_RESET':
      return action.value
    default: 
      return state
  }
}

export const notebookImageReviewStatus = (state = {}, action) => {
  switch(action.type) {
    case 'SET_NOTEBOOK_IMAGE_REVIEW_STATUS':
      return action.status
    default:
      return state
  }
}

export const notebookImageOutline = (state = { outline: [], hasPrevPage: false, hasNextPage: false, totalNotebookImageAmount: 0 }, action) => {
  switch(action.type) {
    case 'SET_NOTEBOOK_IMAGE_OUTLINE':
      return action
    default:
      return state
  }
}

export const projectStats = (state = {}, action) => {
  switch(action.type) {
    case 'SET_PROJECT_STATS': 
      return action.stats
    default: 
      return state
  }
}

export const currentProjectId = (state = '', action) => {
  switch (action.type) {
    case 'SET_CURRENT_PROJECT_ID': 
      return action.projectId
    default: 
      return state
  }
}

export const noProjectsFound = (state = false, action) => {
  switch(action.type) {
    case 'SET_NO_PROJECTS_FOUND': 
      return action.status
    default: 
      return state
  }
}

export const modelTrainStatus = (state = { success: false, inprogress: false }, action) => {
  switch(action.type) {
    case 'SET_MODEL_TRAIN_STATUS': 
      return { success: action.success, inprogress: action.inprogress } 
    default: 
      return state
  }
}

