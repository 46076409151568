import React from 'react';
import { Link } from 'react-router-dom'
import { Fragment } from 'react'
import { Menu, Transition } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/solid'

const items = [
  { name: 'New Project', href: '/project/new' }
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const Header = ({ 
  paragraph, 
  notebook, 
  disableNotebookButton, 
  paragraphTitle, 
  projects, 
  currentProjectId, 
  onProjectChange
}) => (
  <header className="bg-white shadow-sm lg:static lg:overflow-y-visible">
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
      <div className="flex">
        <div className="flex md:left-0 md:inset-y-0 lg:static xl:col-span-1">
          <div className="flex-shrink-0 flex items-center">
            <Link to={`/${currentProjectId}`}>
              <img className="block h-8 w-auto" src="/logo.png" alt="Workflow"/>
            </Link>
          </div>
        </div>
        <div className="flex-1 py-2 md:px-2">
          <select
            id="location"
            name="location"
            className="block rounded-md border-gray-300 pl-3 pr-10 pt-1 pb-1 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 text-gray-500"
            onChange={onProjectChange}
            value={currentProjectId}
            style={{"fontSize": "0.8rem"}}
          >
            {projects.map((project, index) => 
              <option value={project.identifier}>{project.name}</option>
            )}
          </select>
        </div>
        <div className="flex-1 hidden lg:flex lg:items-center lg:justify-end xl:col-span-4">
          {!disableNotebookButton &&
            <div className="inline-flex rounded-md shadow-sm">
              <Link to={`/${currentProjectId}/notebook/new`}>
                <button
                  type="button"
                  className="relative inline-flex items-center rounded-l-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500"
                >
                  New Notebook
                </button>
              </Link>
              <Menu as="div" className="relative -ml-px block">
                <Menu.Button className="relative inline-flex items-center rounded-r-md border border-gray-300 bg-white px-2 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500">
                  <span className="sr-only">Open options</span>
                  <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
                </Menu.Button>
                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items className="absolute right-0 z-10 mt-2 -mr-1 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                    <div className="py-1">
                      {items.map((item) => (
                        <Menu.Item key={item.name}>
                          {({ active }) => (
                            <Link
                              to={item.href}
                              className={classNames(
                                active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                'block px-4 py-2 text-sm'
                              )}
                            >
                              {item.name}
                            </Link>
                          )}
                        </Menu.Item>
                      ))}
                    </div>
                  </Menu.Items>
                </Transition>
              </Menu>
            </div>
          }
        </div>
      </div>
    </div>
  </header>
) 

export default Header