import React, { Component } from 'react';
import ParagraphReviewComponent from './paragraphReviewComponent';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
// import * as reviewActions from '../actions/review';
import * as paragraphActions from './paragraphActions'
import * as notebookActions from '../actions/notebook'
import _ from 'lodash'

class ParagraphReview extends Component {

  constructor(props) {
    super(props)

    this.state = {
      maxImgWidth: 1000,
      notebookId: this.props.match.params.notebookId,
      notebookImageId: this.props.match.params.notebookImageId,
      isSubmitDisabled: false, 
      outlineOffset: 1,
      outlineCount: 20
    }

    this.onSubmitClick = this.onSubmitClick.bind(this)
    this.handleAnnotationDelete = this.handleAnnotationDelete.bind(this)
    this.handleAnnotationMouseOver = this.handleAnnotationMouseOver.bind(this)
    this.handleAnnotationMouseOut = this.handleAnnotationMouseOut.bind(this)
    this.toggleAnnotationActivation = this.toggleAnnotationActivation.bind(this)
    this.onPrevOutlinePageClick = this.onPrevOutlinePageClick.bind(this)
    this.onNextOutlinePageClick = this.onNextOutlinePageClick.bind(this)
    this.onPolygonDeleteClick = this.onPolygonDeleteClick.bind(this)
    this.formatFilename = this.formatFilename.bind(this)
  }

  componentDidMount() {
    this.props.actions.fetchNotebook(this.props.match.params.notebookId)
    this.props.actions.fetchParagraphReviewImage(this.props.match.params.notebookId, this.props.match.params.notebookImageId, this.state.maxImgWidth)

    this.props.actions.fetchNotebookImageStatus(this.props.match.params.notebookId)
    this.props.actions.fetchNotebookImageOutline(this.props.match.params.notebookId, 
                                                this.props.match.params.notebookImageId,
                                                this.state.outlineOffset, 
                                                this.state.outlineCount
                                              )
    
    document.addEventListener('keydown', e => {

      if (e.ctrlKey && e.key === 's') {
        this.setState({ isSubmitDisabled: !this.state.isSubmitDisabled })
        this.props.actions.uploadParagraphAnnotations(
          this.props.match.params.notebookId, 
          this.props.match.params.notebookImageId, 
          this.state.maxImgWidth
        )
      }
    });

    if(this.props.match.params.projectId && this.props.currentProjectId === "") {
      this.props.actions.setCurrentProjectId(this.props.match.params.projectId)
    }
  }

  componentDidUpdate() {
    const notebookId = this.props.match.params.notebookId
    const notebookImageId = this.props.match.params.notebookImageId

    if(this.state.notebookId !== notebookId || this.state.notebookImageId !== notebookImageId) {
      this.setState({ 
        isSubmitDisabled: false,
        notebookId: notebookId, 
        notebookImageId: notebookImageId, 
        outlineCount: 20
      })

      this.props.actions.resetSaveParagraphStatus()
      this.props.actions.fetchNotebook(this.props.match.params.notebookId)
      this.props.actions.fetchParagraphReviewImage(this.props.match.params.notebookId, this.props.match.params.notebookImageId, this.state.maxImgWidth)
      this.props.actions.fetchNotebookImageStatus(this.props.match.params.notebookId)
      this.props.actions.fetchNotebookImageOutline(this.props.match.params.notebookId, 
                                                  this.props.match.params.notebookImageId,
                                                  this.state.outlineOffset, 
                                                  this.state.outlineCount
                                                )
    }
  }

  onSubmitClick() {
    if(!this.state.isSubmitDisabled) {
      this.setState({ isSubmitDisabled: !this.state.isSubmitDisabled })
      this.props.actions.uploadParagraphAnnotations(
        this.props.match.params.notebookId, 
        this.props.match.params.notebookImageId, 
        this.state.maxImgWidth
      )
    }
  }

  onNextOutlinePageClick() {
    this.props.actions.fetchNotebookImageOutline(this.props.match.params.notebookId, 
                                                  this.props.match.params.notebookImageId,
                                                  this.state.outlineOffset, 
                                                  this.state.outlineCount
                                                )
  }

  onPrevOutlinePageClick() {
    this.props.actions.fetchNotebookImageOutline(this.props.match.params.notebookId, 
                                                  this.props.match.params.notebookImageId,
                                                  this.state.outlineOffset, 
                                                  this.state.outlineCount
                                                )
  }

  handleAnnotationDelete(identifier) {
    this.props.actions.deleteImageAnnotation(identifier)
  }

  handleAnnotationMouseOver(identifier) {
    this.props.actions.setAnnotationMouseOver(identifier)
  }

  handleAnnotationMouseOut(identifier) {
    this.props.actions.setAnnotationMouseOut(identifier)
  }

  toggleAnnotationActivation(identifier) {
    this.props.actions.toggleAnnotationActivation(identifier)
  }

  onPolygonDeleteClick(identifier) {
    // Delete line with identifier from Store 
    const updatedLines = _.filter(this.props.lines, (o) => o.identifier !== identifier)

    this.props.actions.deletePolygonLine(updatedLines)
  }

  formatFilename(filename) {
    const nameWithoutExtension = filename.substring(0, filename.lastIndexOf('.'));
  
    const truncatedName = nameWithoutExtension.length > 6
      ? nameWithoutExtension.substring(0, 6) + '..' 
      : nameWithoutExtension;
  
    return truncatedName;
  }

  render() {
    return (
      <ParagraphReviewComponent 
        image={this.props.paragraphReviewImage}
        nextParagraphReviewImage={this.props.prevNextParagraph.next.identifier}
        prevParagraphReviewImage={this.props.prevNextParagraph.prev.identifier}
        onSubmitClick={this.onSubmitClick}
        maxImgWidth={this.state.maxImgWidth}
        notebook={this.props.notebook}
        paragraphSaveSuccess={this.props.paragraphSaveSuccess}
        handleAnnotationDelete={this.handleAnnotationDelete}
        handleAnnotationMouseOver={this.handleAnnotationMouseOver}
        handleAnnotationMouseOut={this.handleAnnotationMouseOut}
        toggleAnnotationActivation={this.toggleAnnotationActivation}
        reviewStatus={this.props.reviewStatus}
        notebookImageOutline={this.props.notebookImageOutline}
        outlineOffset={this.state.outlineOffset}
        outlineCount={this.state.outlineCount}
        onPrevOutlinePageClick={this.onPrevOutlinePageClick}
        onNextOutlinePageClick={this.onNextOutlinePageClick}
        currentProjectId={this.props.currentProjectId}
        lines={this.props.lines}
        onPolygonDeleteClick={this.onPolygonDeleteClick}
        formatFilename={this.formatFilename}
      />
    )
  } 
}

function mapStateToProps(state) {
  return {
    notebook: state.notebookSingle,
    paragraphReviewImage: state.paragraphReviewImage,
    paragraphSaveError: state.paragraphSaveError, 
    paragraphSaveSuccess: state.paragraphSaveSuccess,
    reviewStatus: state.notebookImageReviewStatus,
    notebookImageOutline: state.notebookImageOutline, 
    prevNextParagraph: state.prevNextParagraph,
    currentProjectId: state.currentProjectId,
    lines: state.polygonLines
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Object.assign({}, paragraphActions, notebookActions), dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ParagraphReview)