export const notebookTitle = (state = '', action) => {
  switch (action.type) {
    case 'SET_NEW_NOTEBOOK_TITLE':
      return action.title
    default:
      return state
  }
}

export const acceptedFiles = (state = [], action) => {
  switch (action.type) {
    case 'SET_ACCEPTED_UPLOAD_FILES': 
      return action.files
    default: 
      return state
  }
}

export const uploadStatus = (state = { uploaded: false, total: false }, action) => {
  switch(action.type) {
    case 'SET_IMAGE_UPLOAD_STATUS':
      return { uploaded: action.uploaded, total: action.total }
    default: 
      return state
  }
}