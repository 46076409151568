export const paragraph = (state = { meta: false, content: [], name: false, comment: false }, action) => {

  let meta = false 
  switch (action.type) {
    case 'SET_SINGLE_PARAGRAPH':
      meta = action.paragraph 
      return { meta: meta, content: action.content, name: action.name, comment: action.comment }
    case 'RESET_PARAGRAPH_STATE':
      return { meta: false, content: [], name: false, comment: false } 
    default:
      return state
  }
}

export const paragraphTitle = (state = '', action) => {
  switch (action.type) {
    case 'SET_PARAGRAPH_TITLE': 
      return action.value
    default:
      return state
  }
}

export const paragraphComment = (state = '', action) => {
  switch (action.type) {
    case 'SET_PARAGRAPH_COMMENT': 
      return action.value
    default:
      return state
  }
}

export const paragraphSaveError = (state = false, action) => { 
  switch(action.type) {
    case 'SAVE_PARAGRAPH_ERROR': 
      return action.value
    default: 
      return state
  }
}

const prevNextParagraphState = { 
  prev: { 
    identifier: undefined
  }, 
  next: {
    identifier: undefined
  }
}

export const prevNextParagraph = (state = prevNextParagraphState, action) => {
  switch(action.type) {
    case 'SET_PREV_NEXT_PARAGRAPH': 
      let newState = state
      for(let i = 0; i < action.outline.length; i++) {
        if(action.outline[i].identifier === action.notebookImageId) {
          if(i > 0) {
            newState['prev']['identifier'] = action.outline[i-1].identifier
          } 

          if(i < action.outline.length) {
            newState['next']['identifier'] = action.outline[i+1].identifier
          }
        }
      }
      return newState
    default: 
      return state
  }
}
