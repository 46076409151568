import React from 'react';
import { Link } from 'react-router-dom';
import { ChevronUpIcon, ChevronDownIcon, CheckIcon } from '@heroicons/react/solid';

const OutlineSidebar = ({
  paragraphOutline,
  outlineOffset,
  outlineCount,
  currentProjectId,
  paragraphId,
  notebook,
  onNextOutlinePageClick,
  onPrevOutlinePageClick,
}) => {
  return (
    <div className="xl:w-32 lg:w-24 lg:fixed lg:inset-y-0 lg:border-r lg:border-gray-200 mt-20 lg:pb-4 lg:bg-gray-100">
      <div className="bg-white relative overflow-auto h-full">
        <center>
          {paragraphOutline.hasPrevPage &&
            <span>
              <a
                onClick={onPrevOutlinePageClick}
                className="relative inline-flex items-center px-2 py-2 rounded-t-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 cursor-pointer"
              >
                <span className="sr-only">Previous</span>
                <ChevronUpIcon className="h-5 w-5" aria-hidden="true" />
              </a><br/>
            </span>
          }
          <small><strong>{outlineOffset * outlineCount} - {(outlineOffset + 1) * outlineCount}</strong></small><br/>
          <small>of {paragraphOutline.totalParagraphAmount}</small>
        </center>

        
        {paragraphOutline.outline.map((imageOutline, index) =>
          <Link to={`/${currentProjectId}/text-line-review/${notebook.identifier}/${imageOutline.identifier}`} className="relative">
            <div 
              key={index} 
              className={`object-cover 
                ${imageOutline.identifier === paragraphId ? 'bg-white' : 'bg-gray-100'}
                ${imageOutline.identifier === paragraphId ? 'opacity-100' : 'opacity-75'}
                hover:opacity-100 pt-4 pb-4 pl-3 pr-3 hover:bg-white
                focus-within:ring-offset-2 focus-within:ring-indigo-500 relative
              `}
            >
              <img src={window.location.protocol + '//' + window.location.hostname + ':' + window.location.port + '/' + imageOutline.thumb_image_url} 
                // style={{maxWidth: 70}} 
                className="rounded-t-md"
                alt=""
              />
              
              {/* {imageOutline.checked_for_inheritance !== 1 && 
                <span className="inline-flex items-center px-1 py-1 rounded-full text-xs font-medium bg-indigo-100 text-indigo-800 absolute" style={{ bottom: '1em', left: '0.5em' }}>
                  <svg className="-ml-1.5 mr-1.5 h-2 w-2 text-indigo-400" fill="currentColor" viewBox="0 0 8 8" style={{ marginLeft: 'auto', marginRight: 'auto'}}>
                    <circle cx={4} cy={4} r={3} />
                  </svg>
                </span>
              } */}

              {imageOutline.checked_for_inheritance === 1 &&
                <span className="inline-flex items-center px-1 py-1 rounded-full text-xs font-medium bg-green-600 text-green-800 absolute" style={{ bottom: '2em', left: '1.5em' }}>
                  <CheckIcon className="text-green-100 h-4 w-4"></CheckIcon>
                </span>
              }
              <small>
                <center>
                  <div className={`p-2 rounded-b-md
                    ${imageOutline.identifier === paragraphId ? 'bg-indigo-800 text-white' : 'bg-white'}
                  `}>
                    {imageOutline.offset}
                  </div>
                </center>
              </small>
            </div>
          </Link>
        )}
        <center>
          <small><strong>{outlineOffset * outlineCount} - {(outlineOffset + 1) * outlineCount}</strong></small><br/>
          <small>of {paragraphOutline.totalParagraphAmount}</small><br/>
          {paragraphOutline.hasNextPage &&
            <a
              onClick={onNextOutlinePageClick}
              className="relative inline-flex items-center px-2 py-2 rounded-b-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 cursor-pointer"
            >
              <span className="sr-only">Next</span>
              <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
            </a>
          }
        </center>
      </div>
    </div>
  )
}

export default OutlineSidebar;