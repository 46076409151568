import _ from 'lodash';
import simplify from '../utils/simplify';
import undoable from 'redux-undo';

function makeid(length) {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  let counter = 0;
  while (counter < length) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
    counter += 1;
  }
  return result;
}

export const polygonLines = (state = [], action) => {
  switch (action.type) {
    case 'CANVAS_POLYGON_INIT_LINES': 
      let polygonLines = []
      action.lines.forEach((polygon) => {
        let polygonProjection = []
        polygon.forEach( (point) => {
          const pointProjection = {
            x: point.x * action.canvasDim.w / action.imgDim.w, 
            y: point.y * action.canvasDim.w / action.imgDim.w
          }
          polygonProjection = [...polygonProjection, pointProjection]
        })

        polygonLines = [...polygonLines, {
          points: polygonProjection, 
          active: false, 
          isDragged: false, 
          identifier: makeid(8)
        }]
      })

      return polygonLines
    case 'CANVAS_POLYGON_SET_LINES': 
      return action.lines
    default: 
      return state 
  }
}

export const polygonPoints = (state = [], action) => {
  switch (action.type) {
    case 'CANVAS_POLYGON_INIT_POINTS': 
      let polygonPoints = []
      action.points.forEach((polygon) => {
        let polygonProjection = []
        polygon.forEach( (point) => {
          const pointProjection = { 
            x: point.x * action.canvasDim.w / action.imgDim.w, 
            y: point.y * action.canvasDim.w / action.imgDim.w
          }
          polygonProjection = [...polygonProjection, pointProjection]
        })
        polygonPoints = [...polygonPoints, polygonProjection]
      })

      return polygonPoints
    case 'CANVAS_POLYGON_SET_POINT': 
      return action.points
    default: 
      return state 
  }
}

export const imgStageSize = (state = { x: undefined, y: undefined }, action) => {
  switch (action.type) {
    case 'CANVAS_INIT_STAGE_SIZE': 
      return action.imgStageSize
    default: 
      return state
  }
}

export const polygonSimplifiedPoints = (state = [], action) => {
  switch (action.type) {
    case 'CANVAS_POLYGON_SET_SIMPLIFIED_POINTS': 
      return action.simplifiedPoints
    default: 
      return state 
  }
}

export const polygonIsMovingPoint = (state = false, action) => {
  switch (action.type) {
    case 'CANVAS_POLYGON_IS_MOVING_POINT': 
      return action.isMovingPoint
    default: 
      return state 
  }
}

export const canvasIsMouseActive = (state = false, action) => {
  switch (action.type) {
    case 'CANVAS_IS_MOUSE_ACTIVE':
      return action.canvasIsMouseActive
    default: 
      return state 
  }
}

export const canvasDrawingMode = (state = 'polygon', action) => {
  switch (action.type) {
    case 'CANVAS_SET_DRAWING_MODE': 
      return action.drawingMode 
    default: 
      return state 
  }
}

export const rectangles = (state = [], action) => {
  switch (action.type) {
    case 'CANVAS_SET_RECTANGLES': 
      return action.rectangles 
    default: 
      return state 
  }
}

export const bandsBase = (state = [], action) => {
  switch (action.type) {
    case 'CANVAS_SET_BANDS': 
      return action.bands 
    case 'DELETE_CANVAS_BAND':
      const newBands =  _.filter(action.bands, function(o) { 
        return o.identifier !== action.identifier; 
      });

      return newBands
    default: 
      return state 
  }
}

export const bands = undoable(bandsBase, {
  ignoreInitialState: true
})

export const canvasScale = (state = 1, action) => {
  switch (action.type) {
    case 'CANVAS_SET_SCALE': 
      return action.scale 
    default: 
      return state
  }
}

export const canvasPosition = (state = { x: 0, y: 0 }, action) => {
  switch (action.type) {
    case 'CANVAS_SET_POSITION': 
      return action.position 
    default: 
      return state
  }
}

export const canvasUndoStack = (state = [], action) => {
  switch (action.type) {
    case 'CANVAS_SET_UNDO_STACK': 
      return action.undoStack
    default: 
      return state 
  }
}

export const canvasRedoStack = (state = [], action) => {
  switch (action.type) {
    case 'CANVAS_SET_REDO_STACK': 
      return action.redoStack 
    default: 
      return state
  }
}

export const polygons = (state = [], action) => {
  switch (action.type) {
    case 'CANVAS_INIT_POLYGONS': 
      let initialPolygons = []
      action.polygons.forEach((polygon) => {
        let initialPolygon = {}
        initialPolygon['id'] = makeid(8)
        initialPolygon['isactive'] = false
        let points = []
        let anchors = []
        polygon.forEach( (point) => {
          const pointProjection = { 
            x: point.x * action.canvasDim.w / action.imgDim.w, 
            y: point.y * action.canvasDim.w / action.imgDim.w
          }

          points.push(pointProjection.x, pointProjection.y)
          anchors.push({
            id: makeid(8), 
            ...pointProjection
          })
        })

        initialPolygon['points'] = points 
        initialPolygon['anchors'] = anchors
        
        initialPolygons.push(initialPolygon)
      })
      return initialPolygons
    case 'CANVAS_ADD_POLYGON': 
      let initialPolygon = {}
      initialPolygon['id'] = makeid(8)
      initialPolygon['isactive'] = true
      let points = []
      let anchors = []
      points.push(action.point.x, action.point.y)
      anchors.push({
        id: makeid(8), 
        ...action.point
      })

      initialPolygon['points'] = points 
      initialPolygon['anchors'] = anchors

      return [...state, initialPolygon]
    case 'CANVAS_ADD_POLYGON_POINT': 

      return state.map(polygon => {
        if (polygon.isactive) {
          const pointList = [...polygon.points, action.point.x, action.point.y]
          // Transform pointList from flat array of points into object of { x, y } pairs
          const pointListDict = _.chunk(pointList, 2).map(([x, y]) => ({ x, y }));
          const simplifiedPoints = simplify(pointListDict, 0.25)
          
          // Transform newPoints from object of { x, y } pairs back into flat array of points
          const points = simplifiedPoints.map(({ x, y }) => [x, y]).flat();

          // extend each object in simplifiedPoints with a unique identifier
          const anchors = simplifiedPoints.map(point => ({ id: makeid(8), ...point }));

          return {...polygon, 
            points: points, 
            anchors: anchors
          }
        } else {
          return polygon
        }
      })
    case 'CANVAS_MOVE_ANCHOR': 
      return state.map(polygon => {
        if(polygon.id !== action.polygonId) return polygon
        
        const updatedAnchors = polygon.anchors.map(anchor => {
          if (anchor.id === action.anchorId) {
            // Update the coordinates of the anchor
            return {
              ...anchor,
              x: action.position.x,
              y: action.position.y
            };
          } else {
            return anchor;
          }
        })

        // Update the points array if necessary
        const points = updatedAnchors.map(anchor => [anchor.x, anchor.y]).flat();

        return {
          ...polygon,
          anchors: updatedAnchors,
          points: points
        };
      })

      // return newState
    case 'CANVAS_DEACTIVATE_POLYGONS': 
      return state.map(polygon => ({...polygon, isactive: false }))
    default: 
      return state
  }
}