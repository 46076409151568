import React from 'react';
import { Link } from 'react-router-dom'
import RecurringTranscriptionsWidget from '../RecurringTranscriptionsWidget/RecurringTranscriptionsWidget'
import AssignedReviewWidget from '../assignedReviewWidget/AssignedReviewWidget';
import { ArrowUpIcon, ArrowDownIcon } from '@heroicons/react/solid'

import Header from '../Header/header'

const stats = [
  {
    'name': 'Reviewed images', 
    'stat': '13400', 
    'previousStat': '134000', 
    'change': '100%', 
    'changeType': 'increase', 
    'reviewedStatKey': 'reviewed_notebook_images', 
    'totalStatKey': 'total_notebook_images'
  }, 
  {
    'name': 'New annotations since last training', 
    'stat': '13400', 
    'previousStat': '134000', 
    'change': '100%', 
    'changeType': 'increase', 
    'reviewedStatKey': 'reviewed_paragraphs', 
    'totalStatKey': 'total_paragraphs'
  }, 
  {
    'name': 'Acceptance Rate', 
    'stat': '13400', 
    'previousStat': '134000', 
    'change': '100%', 
    'changeType': 'increase', 
    'reviewedStatKey': 'reviewed_lines', 
    'totalStatKey': 'total_lines'
  }
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const NotebookList = ({ 
  onNewNotebookClick, 
  notebooks, 
  projectStats, 
  noProjectsFoundStatus, 
  currentProjectId, 
  currentProjectName, 
  onModelRetrainClick, 
  modelTrainStatus
}) => (
  <div className="bg-gray-100 min-h-screen">
    <Header/>
    <div className="flex-1 flex items-stretch overflow-hidden">
      <main className="flex-1 overflow-y-auto">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-10">
          <div className="flex">
            <h1 className="flex-1 text-2xl font-bold text-gray-900">Notebooks</h1>
            {modelTrainStatus.inprogress === true &&
              <button 
                className="opacity-60 ml-3 cursor-default inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                disabled
              >
                Model training in progress...
              </button>
            }

            {modelTrainStatus.inprogress === false && 
              <button 
                className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                onClick={onModelRetrainClick}
              >
                Retrain model
              </button>
            }

            {currentProjectName === 'tomography' &&
              <button 
                className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                Export 3D .obj file
              </button>
            }
            
          </div>

          {noProjectsFoundStatus === true &&
            <div className="max-w-7xl mx-auto sm:py-6">
              <div className="bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6">
                <div className="md:grid md:grid-cols-3 md:gap-6">
                  <div className="md:col-span-1">
                    <h3 className="text-lg font-medium leading-6 text-gray-900">No projects found</h3>
                    <p className="mt-1 text-sm text-gray-500">
                      Create a project to get started. 
                    </p>
                  </div>
                  <div className="mt-5 md:mt-0 md:col-span-2">
                    <Link to="/project/new">
                      <button className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                        Create project
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          }

          {noProjectsFoundStatus === false &&
            <span>
              <dl className="mt-5 grid grid-cols-1 divide-y divide-gray-200 overflow-hidden rounded-lg bg-white shadow md:grid-cols-3 md:divide-y-0 md:divide-x">
                {stats.map((item) => (
                  <div key={item.name} className="px-4 py-5 sm:p-6">
                    <dt className="text-base font-normal text-gray-900">{item.name}</dt>
                    <dd className="mt-1 flex items-baseline justify-between md:block lg:flex">
                      <div className="flex items-baseline text-2xl font-semibold text-indigo-600">
                        {projectStats[item.reviewedStatKey]}
                        <span className="ml-2 text-sm font-medium text-gray-500">from {projectStats[item.totalStatKey]}</span>
                      </div>

                      <div
                        className={classNames(
                          'bg-indigo-100 text-indigo-800',
                          'inline-flex items-baseline px-2.5 py-0.5 rounded-full text-sm font-medium md:mt-2 lg:mt-0'
                        )}
                      >
                        {Math.ceil((projectStats[item.reviewedStatKey] / projectStats[item.totalStatKey]) * 100)}%
                      </div>
                    </dd>
                  </div>
                ))}
              </dl>
              
              {currentProjectName === 'talens' &&
                <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">
                  <RecurringTranscriptionsWidget/>
                  <AssignedReviewWidget/>
                </dl>
              }

              <section className="mt-8 pb-16" aria-labelledby="gallery-heading">
                <ul
                  className="grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-3 sm:gap-x-6 md:grid-cols-4 lg:grid-cols-3 xl:grid-cols-4 xl:gap-x-8"
                >
                  {notebooks.map((notebook) => (
                    <Link to={`${currentProjectId}/notebook/${notebook.identifier}`}>
                      <li key={notebook.name} className="relative">
                        <p className="mt-4 block text-sm font-medium text-gray-900 truncate pointer-events-none">
                          {notebook.name}
                        </p>
                        <p className="block text-sm font-medium text-gray-500 pointer-events-none">{notebook.paragraph_amount} {notebook.paragraph_amount > 1 ? 'images' : 'image'}</p>
                        <div
                          className='mt-4 mb-4 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500 group block w-full aspect-w-10 aspect-h-7 rounded-lg overflow-hidden'
                        >
                          <img
                            src={notebook.featured_image}
                            alt=""
                            className='object-cover pointer-events-none'
                          />
                          <button type="button" className="absolute inset-0">
                            <span className="sr-only">View details for {notebook.name}</span>
                          </button>
                        </div>
                        <div className="relative pt-1">
                          <div className="flex mb-2 items-center justify-between">
                            <div>
                              <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-green-600 bg-green-200">
                                Image Review
                              </span>
                            </div>
                            <div className="text-right">
                              {notebook &&
                                <span className="text-xs font-semibold inline-block text-green-600">
                                  {notebook.reviewed_notebook_images} / {notebook.total_notebook_images}
                                </span>
                              }
                            </div>
                          </div>
                          <div className="overflow-hidden h-2 mb-4 text-xs flex rounded bg-green-200">
                            {notebook &&
                              <div style={{ width: `${((notebook.reviewed_notebook_images / notebook.total_notebook_images) * 100).toFixed(0)}%` }} className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-green-500"></div>
                            }
                          </div>
                        </div>
                        <div className="relative pt-1">
                          <div className="flex mb-2 items-center justify-between">
                            <div>
                              <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-green-600 bg-green-200">
                                Text Review
                              </span>
                            </div>
                            <div className="text-right">
                              {notebook &&
                                <span className="text-xs font-semibold inline-block text-green-600">
                                  {notebook.reviewed_line_images} / {notebook.total_line_images}
                                </span>
                              }
                            </div>
                          </div>
                          <div className="overflow-hidden h-2 mb-4 text-xs flex rounded bg-green-200">
                            {notebook &&
                              <div style={{ width: `${((notebook.reviewed_line_images / notebook.total_line_images) * 100).toFixed(0)}%` }} className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-green-500"></div>
                            }
                          </div>
                        </div>
                      </li>
                    </Link>
                  ))}
                </ul>
              </section> 
            </span>
          }

        </div>
      </main> 
    </div>
  </div>
)

export default NotebookList