import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

const Toolbar = () => {
  const drawingMode = useSelector((state) => state.canvasDrawingMode)
  const scale = useSelector((state) => state.canvasScale)
  const dispatch = useDispatch();

  const setDrawingMode = (mode) => {
    dispatch({ type: 'CANVAS_SET_DRAWING_MODE', drawingMode: mode });
  };

  const handleZoomIn = () => {
    dispatch({ type: 'CANVAS_SET_SCALE', scale: Math.min(scale + 0.1, 2) });
  };

  const handleZoomOut = () => {
    dispatch({ type: 'CANVAS_SET_SCALE', scale: Math.max(scale - 0.1, 0.5) });
  };

  return (
    <div className="toolbar">
      <button
        onClick={() => setDrawingMode('rect')}
        className={
          (drawingMode === 'rect' ? 'bg-indigo-600 text-white ring-black-300 hover:bg-indigo-700' : 'bg-white text-gray-900 ring-gray-300 hover:bg-gray-50') +
          ` rounded px-2 py-1 text-sm font-semibold shadow-sm ring-1 ring-inset`
        }
      >
        Rectangle
      </button>
      <button
        onClick={() => setDrawingMode('polygon')}
        className={
          (drawingMode === 'polygon' ? 'bg-indigo-600 text-white ring-black-300 hover:bg-indigo-700' : 'bg-white text-gray-900 ring-gray-300 hover:bg-gray-50') +
          ` rounded px-2 py-1 text-sm font-semibold shadow-sm ring-1 ring-inset`
        }
      >
        Polygon
      </button>
      <button
        onClick={() => setDrawingMode('band')}
        className={
          (drawingMode === 'band' ? 'bg-indigo-600 text-white ring-black-300 hover:bg-indigo-700' : 'bg-white text-gray-900 ring-gray-300 hover:bg-gray-50') +
          ` rounded px-2 py-1 text-sm font-semibold shadow-sm ring-1 ring-inset`
        }
      >
        Band
      </button>
      <button 
        className="rounded bg-white px-2 py-1 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
        onClick={handleZoomIn}
      >Zoom In</button>
      <button 
        className="rounded bg-white px-2 py-1 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
        onClick={handleZoomOut}
      >Zoom Out</button>
    </div>
  );
};

export default Toolbar;