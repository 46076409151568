import React, { Component } from 'react';
import SearchAndReplaceComponent from './SearchAndReplaceComponent';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as SearchAndReplaceActions from './SearchAndReplaceActions'
import * as notebookActions from '../actions/notebook'

class SearchAndReplace extends Component {

  constructor(props) {
    super(props)

    this.state = {
      replacementValue: '', 
      lineConfSort: '', 
      textConfSort: '', 
      replaceExactMatch: true, 
      searchExactMatch: false
    }
    this.onPreviewClick = this.onPreviewClick.bind(this)
    this.onReplaceChange = this.onReplaceChange.bind(this)
    this.onSingleValueChange = this.onSingleValueChange.bind(this)
    this.onSubmitClick = this.onSubmitClick.bind(this)
    this.onLineConfSortClick = this.onLineConfSortClick.bind(this)
    this.onTextConfSortClick = this.onTextConfSortClick.bind(this)
    this.onReplaceExactMatchChange = this.onReplaceExactMatchChange.bind(this)
    this.onSingleSelectChange = this.onSingleSelectChange.bind(this)
    this.onSearchExactMatchChange = this.onSearchExactMatchChange.bind(this)
  }

  componentDidMount() {
    this.props.actions.fetchSearchResults(
      this.props.match.params.query, 
      this.props.match.params.cursor, 
      this.props.match.params.offset,
      this.state.searchExactMatch
    )
    
    if(this.props.match.params.projectId && this.props.currentProjectId === "") {
      this.props.actions.setCurrentProjectId(this.props.match.params.projectId)
    }
  }

  componentDidUpdate() {
    if(this.props.saveReplacedValuesSuccess) {
      this.props.actions.resetSaveReplacedValuesSuccess()
      this.props.actions.fetchSearchResults(
        this.props.match.params.query, 
        this.props.match.params.cursor, 
        this.props.match.params.offset, 
        this.state.searchExactMatch)
    }
  }

  onLineConfSortClick(event) {
    // const sortedBy = event.target.getAttribute('sortby')
    if(this.state.lineConfSort === '') {
      this.setState({ lineConfSort: 'desc' })
    } else if(this.state.lineConfSort === 'desc') {
      this.setState({ lineConfSort: 'asc' })
    } else if(this.state.lineConfSort === 'asc') {
      this.setState({ lineConfSort: '' })
    }

    this.props.actions.fetchSearchResults(
      this.props.match.params.query, 
      this.props.match.params.cursor, 
      this.props.match.params.offset, 
      this.state.lineConfSort, 
      this.state.textConfSort, 
      this.state.searchExactMatch
    )
  }

  onTextConfSortClick(event) {

    if(this.state.textConfSort === '') {
      this.setState({ textConfSort: 'desc' })
    } else if(this.state.textConfSort === 'desc') {
      this.setState({ textConfSort: 'asc' })
    } else if(this.state.textConfSort === 'asc') {
      this.setState({ textConfSort: '' })
    }

    this.props.actions.fetchSearchResults(
      this.props.match.params.query, 
      this.props.match.params.cursor, 
      this.props.match.params.offset, 
      this.state.lineConfSort,
      this.state.textConfSort, 
      this.state.searchExactMatch
    )
  }

  onPreviewClick() {
    this.props.actions.replaceValues(this.props.match.params.query, this.state.replacementValue, this.state.replaceExactMatch)
  }

  onReplaceChange(event) {
    this.setState({ 
      replacementValue: event.target.value
    })
  }

  onSingleValueChange(event) {
    const identifier = event.target.getAttribute('identifier')
    console.log({ identifier: identifier })
    this.props.actions.replaceValue(event.target.value, identifier)
  }

  onSubmitClick() {
    this.props.actions.saveReplacedValues()
  }

  onReplaceExactMatchChange() {
    this.setState({
      replaceExactMatch: !this.state.replaceExactMatch
    })
  }

  onSearchExactMatchChange() {
    this.setState({
      searchExactMatch: !this.state.searchExactMatch
    })

    this.props.actions.fetchSearchResults(
      this.props.match.params.query, 
      this.props.match.params.cursor, 
      this.props.match.params.offset, 
      this.state.lineConfSort, 
      this.state.textConfSort, 
      !this.state.searchExactMatch
    )
  }

  onSingleSelectChange(event) {
    const identifier = event.target.getAttribute('identifier')
    this.props.actions.toggleSingleSelect(identifier)
  }

  render() {
    return (
      <SearchAndReplaceComponent
        lineSearchResults={this.props.lineSearchResults}
        lineSearchResultsAmount={this.props.lineSearchResultsAmount}
        cursor={this.props.match.params.cursor}
        offset={this.props.match.params.offset}
        query={this.props.match.params.query}
        onPreviewClick={this.onPreviewClick}
        onReplaceChange={this.onReplaceChange}
        replacementValue={this.state.replacementValue}
        onSingleValueChange={this.onSingleValueChange}
        onSubmitClick={this.onSubmitClick}
        onLineConfSortClick={this.onLineConfSortClick}
        onTextConfSortClick={this.onTextConfSortClick}
        replaceExactMatch={this.state.replaceExactMatch}
        onReplaceExactMatchChange={this.onReplaceExactMatchChange}
        onSingleSelectChange={this.onSingleSelectChange}
        searchExactMatch={this.state.searchExactMatch}
        onSearchExactMatchChange={this.onSearchExactMatchChange}
      />
    )
  }

}


function mapStateToProps(state) {
  return {
    lineSearchResults: state.lineSearchResults,
    lineSearchResultsAmount: state.lineSearchResultsAmount, 
    saveReplacedValuesSuccess: state.saveReplacedValuesSuccess,
    saveReplacedValuesError: state.saveReplacedValuesError
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Object.assign({}, SearchAndReplaceActions, notebookActions), dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SearchAndReplace)
