import * as types from '../actions/types'
import axios from 'axios'
import _ from 'lodash'

export const fetchNextParagraph = (notebookId, currentProjectId) => {
  axios({
    method: 'GET',
    url: `/api/paragraph-review/${notebookId}/next`
  }).then((resp) => {
    const paragraphId = resp.data.paragraph.identifier

    window.location.replace(`/${currentProjectId}/text-line-review/${notebookId}/${paragraphId}`)
  }).catch(function(err) {
    console.log('fetchLineReviewImage error: ', err)
  })
}

export const resetParagraphAnnotations = () => {
  return (dispatch, getState) => {
    dispatch({
      type: types.SET_TEXT_LINE_ANNOTATIONS, 
      annotations: []
    })
  }
}

export const fetchParagraph = (notebookId, paragraphId, imgPresentationWidth) => {
  return (dispatch, getState) => {
    axios({
      method: 'GET',
      url: `/api/paragraph-review/${notebookId}/${paragraphId}/${imgPresentationWidth}`
    }).then((resp) => {
      const paragraph = resp.data.paragraph
      const annotations = resp.data.annotations
      const nextImage = resp.data.next_paragraph
      const prevImage = resp.data.prev_paragraph
      const paragraphMeta = resp.data.paragraph_meta
      const inheritanceStatus = resp.data.inheritance_status
      const reviewAssignees = resp.data.review_assignees

      dispatch({
        type: types.SET_TEXT_LINE_REVIEW_PARAGRAPH,
        paragraph: paragraph
      })

      dispatch({
        type: types.SET_NEXT_TEXT_LINE_REVIEW_PARAGRAPH,
        lineImage: nextImage
      })

      dispatch({
        type: types.SET_PREV_TEXT_LINE_REVIEW_PARAGRAPH,
        lineImage: prevImage
      })

      dispatch({
        type: types.CANVAS_SET_BANDS, 
        bands: annotations.length > 0 ? annotations : []
      })

      dispatch({
        type: types.SET_PARAGRAPH_INHERITANCE_PROGRESS_STATUS, 
        status: inheritanceStatus
      })

      dispatch({
        type: types.SET_PARAGRAPH_LABELS, 
        labels: _.filter(paragraphMeta, { 'key_type': 'label' })
      })

      dispatch({
        type: types.SET_REVIEW_ASSIGNEES, 
        assignees: reviewAssignees
      })

      const imgStageSize = {
        w: imgPresentationWidth, 
        h: paragraph.img_height * imgPresentationWidth / paragraph.img_width
      }
      dispatch({
        type: 'CANVAS_INIT_STAGE_SIZE', 
        imgStageSize: imgStageSize
      })

    }).catch(function(err) {
      console.log('fetchLineReviewImage error: ', err)
    })
  }
}

export const saveAnnotations = ({ notebookId, paragraphId, canvasBoxWidth }) => {
  return (dispatch, getState) => {
    const annotations = getState().bands.present

    const formData = new FormData()
    formData.append('annotations', JSON.stringify(annotations))
    formData.append('paragraphId', paragraphId)
    formData.append('notebookId', notebookId)
    formData.append('canvasBoxWidth', canvasBoxWidth)

    axios({
      method: 'POST',
      url: `/api/paragraph-line-annotations/${paragraphId}`,
      data: formData
    }).then((resp) => {
      dispatch({
        type: types.SAVE_TEXT_LINES_SUCCESS,
        value: true
      })
    }).catch((err) => {
      console.log(err)
      dispatch({
        type: types.SAVE_LINES_ERROR,
        value: 'Something went wrong'
      })
    })
  }
}

export const annotateBlock = (action) => {
  return (dispatch, getState) => {
    console.log('go for it')
    dispatch({
      type: types.TOGGLE_ANNOTATE_BLOCK, 
      hasAnnotateBlock: action
    })
  }
}

export const fetchParagraphOutline = (notebookId, offset, count, paragraphId) => {
  return (dispatch, getState) => {
    axios({
      method: 'GET',
      url: `/api/paragraph-review/${notebookId}/${paragraphId}/image-outline/${offset}/${count}`
    }).then((resp) => {
      const outline = resp.data.outline
      const hasPrevPage = resp.data.has_prev_page
      const hasNextPage = resp.data.has_next_page
      const totalParagraphAmount = resp.data.total_record_amount

      dispatch({
        type: types.SET_TEXT_LINE_REVIEW_PARAGRAPH_OUTLINE,
        outline: outline, 
        hasPrevPage: hasPrevPage,
        hasNextPage: hasNextPage,
        totalParagraphAmount: totalParagraphAmount
      })
      
    }).catch(function (err) {
      console.log(`fetchLineImageOutline error: ${err}`)
    })
  }
}

export const resetSaveTextLineStatus = () => {
  return (dispatch, getState) => {
    dispatch({
      type: types.RESET_TEXT_LINES_SUCCESS, 
      value: false 
    })
  } 
}

export const setTextLineAnnotations = (annotations) => {
  return (dispatch, getState) => {

    const disabledAnnotations = annotations.map(o => {
      
      // If annotation is drawn from bottom to top, the height is negative.
      // This check makes the height positive so that a line image can be created by:
      // from: y, to: y + height
      if(o.height < 0) {
        o.y = o.y + o.height
        o.height = -o.height
      }

      return ({ ...o, isActive: false })
    })

    dispatch({
      type: types.SET_TEXT_LINE_ANNOTATIONS,
      annotations: disabledAnnotations
    })
  }
}

export const setBandValue = (identifier, value) => {
  return (dispatch, getState) => {

    const annotations = getState().bands.present
    const index = _.findIndex(annotations, {identifier: identifier});
    
    let item = annotations[index]
    item.value = value 

    const newAnnotations = _.unionBy([item], annotations, 'identifier')

    dispatch({
      type: types.CANVAS_SET_BANDS,
      // identifier: identifier,
      // value: value, 
      bands: newAnnotations
    })
  }
}

export const deleteBandAnnotation = (identifier) => {
  return (dispatch, getState) => {
    dispatch({
      type: types.DELETE_CANVAS_BAND,
      identifier: identifier,
      bands: getState().bands.present
    })
  }
}

export const deleteAllTextLineImageAnnotations = () => {
  return (dispatch, getState) => {
    dispatch({
      type: types.DELETE_ALL_TEXT_LINE_ANNOTATIONS
    })
  }
}

export const setBandAssignment = (identifier, assignedTo, assignmentStatus) => {
  // Set the line identifier and the assignee identifier. 
  // The backend needs to know the assigned_by value as well. 
  // For that, backend looks at the authentication credentials. 
  return (dispatch, getState) => {
    const annotations = getState().bands.present
    const index = _.findIndex(annotations, {identifier: identifier});
    
    let item = annotations[index]
    console.log({ assignmentStatus: assignmentStatus })
    if(assignmentStatus) {
      item.assignedTo = assignedTo 
    } else {
      delete item.assignedTo
    }
    
    const newAnnotations = _.unionBy([item], annotations, 'identifier')

    dispatch({
      type: types.CANVAS_SET_BANDS,
      bands: newAnnotations
    })
  }
}