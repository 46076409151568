import React, { Component } from 'react';
import NotebookSingleComponent from '../components/notebookSingle';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as notebookActions from '../actions/notebook'
import * as paragraphActions from '../ParagraphComponent/paragraphActions'
import _ from 'lodash'

class NotebookSingle extends Component {

  constructor(props) {
    super(props)
    this.onPageChange = this.onPageChange.bind(this)
  }

  componentDidMount() {
    const page = this.props.match.params.page ? this.props.match.params.page : 1
    this.props.actions.fetchNotebook(this.props.match.params.notebookId, page)

    // Do this when we come from a previously succesfully saved paragraph
    this.props.actions.resetSaveParagraphStatus()

    if(this.props.match.params.projectId && this.props.currentProjectId === "") {
      this.props.actions.setCurrentProjectId(this.props.match.params.projectId)
    }
  }

  onPageChange(pageNumber) {
    window.location.replace(`/${this.props.currentProjectId}/notebook/${this.props.match.params.notebookId}/${pageNumber}`);
  }

  render() {
    const currentProjectName = _.filter(this.props.projects, { identifier: this.props.currentProjectId})
    const totalPages = this.props.notebook ? this.props.notebook.totalPages : 0
    const currentPage = this.props.match.params.page ? parseInt(this.props.match.params.page) : 1

    const pageNumbers = []
    let startPage, endPage

    if(this.props.notebook.totalPages > 0) {
      if(totalPages <= 10) {
        startPage = 1
        endPage = totalPages
      } else {
        if (currentPage <= 6) {
          startPage = 1
          endPage = 10
        } else if (currentPage + 4 >= totalPages) {
          startPage = totalPages - 9 
          endPage = totalPages
        } else {
          startPage = currentPage - 5 
          endPage = currentPage + 4
        }
      }

      for (let page = startPage; page <= endPage; page++) {
        pageNumbers.push(page);
      }
    }

    return (
      <NotebookSingleComponent
        notebook={this.props.notebook}
        images={this.props.images}
        nextParagraphReviewImage={this.props.nextParagraphReviewImage}
        currentProjectName={currentProjectName.length > 0 ? currentProjectName[0]['name'].toLowerCase() : []}
        currentProjectId={this.props.currentProjectId}
        pageNumbers={pageNumbers}
        currentPage={currentPage}
        onPageChange={this.onPageChange}
        totalPages={totalPages}
      />
    )
  }
}

function mapStateToProps(state) {
  return {
    notebook: state.notebookSingle, 
    images: Object.keys(state.notebookSingle).length !== 0 ? state.notebookSingle.images : [],
    nextParagraphReviewImage: state.nextParagraphReviewImage, 
    currentProjectId: state.currentProjectId,
    projects: state.projects
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Object.assign({}, notebookActions, paragraphActions), dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(NotebookSingle)