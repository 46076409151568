export const SET_ACCEPTED_UPLOAD_FILES = 'SET_ACCEPTED_UPLOAD_FILES';

export const SET_NEW_NOTEBOOK_TITLE = 'SET_NEW_NOTEBOOK_TITLE';

export const SET_NOTEBOOKS = 'SET_NOTEBOOKS';

export const SET_SINGLE_NOTEBOOK = 'SET_SINGLE_NOTEBOOK';

export const SET_SINGLE_PARAGRAPH = 'SET_SINGLE_PARAGRAPH';

export const SET_PARAGRAPH_TITLE = 'SET_PARAGRAPH_TITLE';

export const SET_PARAGRAPH_COMMENT = 'SET_PARAGRAPH_COMMENT';

export const SAVE_PARAGRAPH_SUCCESS = 'SAVE_PARAGRAPH_SUCCESS'; 

export const SAVE_PARAGRAPH_ERROR = 'SAVE_PARAGRAPH_ERROR';

export const RESET_PARAGRAPH_STATE = 'RESET_PARAGRAPH_STATE';

export const SET_IMAGE_UPLOAD_STATUS = 'SET_IMAGE_UPLOAD_STATUS';

export const USER_LOGGED_IN = 'USER_LOGGED_IN'; 

export const USER_LOGIN_ERROR = 'USER_LOGIN_ERROR';

export const SET_LINE_BATCH_REVIEW = 'SET_LINE_BATCH_REVIEW';

export const SET_PREV_PARAGRAPH_REVIEW_IMAGE = 'SET_PREV_PARAGRAPH_REVIEW_IMAGE';

export const SET_PARAGRAPH_REVIEW_IMAGE = 'SET_PARAGRAPH_REVIEW_IMAGE';

export const SET_PARAGRAPH_ANNOTATIONS = 'SET_PARAGRAPH_ANNOTATIONS';

export const SAVE_PARAGRAPH_RESET = 'SAVE_PARAGRAPH_RESET';

export const DELETE_PARAGRAPH_ANNOTATION = 'DELETE_PARAGRAPH_ANNOTATION';

export const SET_ANNOTATION_MOUSE_OVER = 'SET_ANNOTATION_MOUSE_OVER';

export const SET_ANNOTATION_MOUSE_OUT = 'SET_ANNOTATION_MOUSE_OUT';

export const SET_NOTEBOOK_IMAGE_REVIEW_STATUS = 'SET_NOTEBOOK_IMAGE_REVIEW_STATUS';

export const SET_NOTEBOOK_IMAGE_OUTLINE = 'SET_NOTEBOOK_IMAGE_OUTLINE';

// Paragraph
export const SET_PARAGRAPH_INHERITANCE_PROGRESS_STATUS = 'SET_PARAGRAPH_INHERITANCE_PROGRESS_STATUS';

// Line image
export const SET_LINE_REVIEW_IMAGE = 'SET_LINE_REVIEW_IMAGE';
export const SET_NEXT_LINE_REVIEW_IMAGE = 'SET_NEXT_LINE_REVIEW_IMAGE';
export const SET_PREV_LINE_REVIEW_IMAGE = 'SET_PREV_LINE_REVIEW_IMAGE';
export const SET_LINE_ANNOTATIONS = 'SET_LINE_ANNOTATIONS';
export const SET_LINE_IMAGE_OUTLINE = 'SET_LINE_IMAGE_OUTLINE';
export const SET_LINE_ANNOTATION_MOUSE_OVER = 'SET_LINE_ANNOTATION_MOUSE_OVER';
export const SET_LINE_ANNOTATION_MOUSE_OUT = 'SET_LINE_ANNOTATION_MOUSE_OUT'; 
export const DELETE_LINE_ANNOTATION = 'DELETE_LINE_ANNOTATION';
export const DELETE_ALL_LINE_ANNOTATIONS = 'DELETE_ALL_LINE_ANNOTATIONS';
export const SAVE_LINES_SUCCESS = 'SAVE_LINES_SUCCESS';
export const SAVE_LINES_ERROR = 'SAVE_LINES_ERROR';
export const SAVE_LINES_RESET = 'SAVE_LINES_RESET';

// Text Review
export const SET_TEXT_REVIEW_PARAGRAPH_OUTLINE = 'SET_TEXT_REVIEW_PARAGRAPH_OUTLINE';
export const SET_TEXT_REVIEW_PARAGRAPH = 'SET_TEXT_REVIEW_PARAGRAPH';
export const SET_NEXT_TEXT_REVIEW_PARAGRAPH = 'SET_NEXT_TEXT_REVIEW_PARAGRAPH';
export const SET_PREV_TEXT_REVIEW_PARAGRAPH = 'SET_PREV_TEXT_REVIEW_PARAGRAPH'; 
export const SET_TEXT_REVIEW_LINES = 'SET_TEXT_REVIEW_LINES';
export const SET_TEXT_REVIEW_PARAGRAPH_POSITION = 'SET_TEXT_REVIEW_PARAGRAPH_POSITION';

// Text Line review 
export const SET_TEXT_LINE_REVIEW_PARAGRAPH = 'SET_TEXT_LINE_REVIEW_PARAGRAPH';
export const SET_NEXT_TEXT_LINE_REVIEW_PARAGRAPH = 'SET_NEXT_TEXT_LINE_REVIEW_PARAGRAPH';
export const SET_PREV_TEXT_LINE_REVIEW_PARAGRAPH = 'SET_PREV_TEXT_LINE_REVIEW_PARAGRAPH';
export const SET_TEXT_LINE_REVIEW_PARAGRAPH_STATUS = 'SET_TEXT_LINE_REVIEW_PARAGRAPH_STATUS'; 
export const SET_TEXT_LINE_REVIEW_PARAGRAPH_OUTLINE = 'SET_TEXT_LINE_REVIEW_PARAGRAPH_OUTLINE';
export const SET_TEXT_LINE_ANNOTATIONS = 'SET_TEXT_LINE_ANNOTATIONS';
export const DELETE_ALL_TEXT_LINE_ANNOTATIONS = 'DELETE_ALL_TEXT_LINE_ANNOTATIONS';
export const DELETE_TEXT_LINE_ANNOTATION = 'DELETE_TEXT_LINE_ANNOTATION';
export const SET_TEXT_LINE_VALUE = 'SET_TEXT_LINE_VALUE';
export const TOGGLE_ANNOTATE_BLOCK = 'TOGGLE_ANNOTATE_BLOCK';

export const RECURRING_TRANSCRIPTIONS = 'RECURRING_TRANSCRIPTIONS';
export const RECURRING_TRANSCRIPTIONS_FETCH_ERROR = 'RECURRING_TRANSCRIPTIONS_FETCH_ERROR'; 

export const SET_SEARCH_RESULTS = 'SET_SEARCH_RESULTS';
export const SET_SEARCH_RESULTS_FETCH_ERROR = 'SET_SEARCH_RESULTS_FETCH_ERROR'; 
export const SAVE_REPLACED_VALUES_SUCCESS = 'SAVE_REPLACED_VALUES_SUCCESS';
export const SAVE_REPLACED_VALUES_ERROR = 'SAVE_REPLACED_VALUES_ERROR';
export const REPLACE_VALUES = 'REPLACE_VALUES';
export const SET_LINE_SEARCH_RESULTS_TOTAL_AMOUNT = 'SET_LINE_SEARCH_RESULTS_TOTAL_AMOUNT'

export const SAVE_TEXT_LINES_SUCCESS = 'SAVE_TEXT_LINES_SUCCESS'
export const RESET_TEXT_LINES_SUCCESS = 'RESET_TEXT_LINES_SUCCESS'

export const SET_RULES = 'SET_RULES'

export const SET_PARAGRAPH_LABELS = 'SET_PARAGRAPH_LABELS';

export const SET_PROJECT_STATS = 'SET_PROJECT_STATS';
export const SET_AVAILABLE_PROJECTS = 'SET_AVAILABLE_PROJECTS';
export const SET_CURRENT_PROJECT_ID = 'SET_CURRENT_PROJECT_ID';
export const SET_NO_PROJECTS_FOUND = 'SET_NO_PROJECTS_FOUND';

export const SET_REVIEW_ASSIGNEES = 'SET_REVIEW_ASSIGNEES';

export const SET_MODEL_TRAIN_STATUS = 'SET_MODEL_TRAIN_STATUS'; 

export const SET_PREV_NEXT_PARAGRAPH = 'SET_PREV_NEXT_PARAGRAPH';

export const CANVAS_SET_RECTANGLES = 'CANVAS_SET_RECTANGLES';
export const CANVAS_SET_BANDS = 'CANVAS_SET_BANDS';
export const DELETE_CANVAS_BAND = 'DELETE_CANVAS_BAND';