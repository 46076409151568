import React, { Component } from 'react';
import AssignedReviewWidgetComponent from './AssignedReviewWidgetComponent';
// import RecurringTranscriptionsWidgetComponent from './RecurringTranscriptionsWidgetComponent';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
// import * as RecurringTranscriptionsWidgetActions from './RecurringTranscriptionsWidgetActions'

class AssignedReviewWidget extends Component {

  constructor(props) {
    super(props)

    this.state = {}
  }

  componentDidMount() {
    // this.props.actions.fetchRecurrences()
  }

  render() {
    return (
      <AssignedReviewWidgetComponent/>
    )
  }

}


function mapStateToProps(state) {
  return {
    // fetchError: state.recurringTranscriptionsFetchError, 
    // recurringTranscriptions: state.recurringTranscriptions
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Object.assign({}, AssignedReviewWidget), dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AssignedReviewWidget)
