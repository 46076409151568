import React from 'react';

const AssignedReviewWidgetComponent = () => (
  <div className="relative overflow-hidden rounded-lg bg-white shadow">
    <div className="px-4 py-8 sm:px-6 pb-8">
      <h3 className="text-lg leading-6 font-medium text-gray-900">Assigned to you</h3>
      <p className="mt-1 max-w-2xl text-sm text-gray-500">Images to be reviewed by you.</p>
    </div>

    <div className="border-t border-gray-200"></div>
  </div>
)

export default AssignedReviewWidgetComponent