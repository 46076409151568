import React from 'react';
import Header from '../Header/header'

import Notification from '../containers/notification'
import LineCanvasDrawer from '../containers/lineCanvasDrawer'
import TextLineReviewLineItem from '../containers/textLineReviewLineItem'
import RuleManager from '../RuleManager/RuleManager'
import DrawingCanvas from '../DrawingCanvas/drawingCanvas'

import { Link, Redirect } from 'react-router-dom'
import StepsNavigator from '../components/stepsNavigator'
import FooterNavigator from '../components/footerNavigator'
import { HomeIcon } from '@heroicons/react/solid'
import UndoRedo from '../UndoRedo/UndoRedo';

import { ChevronUpIcon, ChevronDownIcon, CheckIcon } from '@heroicons/react/solid'
import OutlineSidebar from '../OutlineSidebar/outlineSidebar';

const steps = [
  { id: '01', name: 'Upload Images', href: '#', status: 'complete' },
  { id: '02', name: 'Review Paragraphs', href: '/paragraph-review/', status: 'complete' },
  { id: '03', name: 'Review Text', href: '/text-review/', status: 'current' },
  { id: '04', name: 'Export Text', href: '#', status: 'upcoming' }
]

// const pages = [


//   { name: 'Upload Images', href: '#', current: false, id: 1, status: 'complete' },
//   { name: 'Review Paragraphs', href: '#', current: true, id: 2, status: 'complete',  },
//   { name: 'Review Text', href: '#', current: false, id: 3, status: 'current',  },
//   { name: 'Export Text', href: '#', current: false, id: 4, status: 'upcoming',  },
// ]
const pages = []

const TextLineReview = ({ 
  key,
  notebook, 
  onPrevClick, 
  onSkipClick, 
  onSubmitClick,
  saveError, 
  textLineSaveSuccess,
  paragraph,
  nextTextReviewParagraph,
  prevTextReviewParagraph,
  reviewStatus, 
  paragraphOutline,
  paragraphId, 
  outlineCount, 
  outlineOffset, 
  onNextOutlinePageClick, 
  onPrevOutlinePageClick, 
  annotations,
  lineCanvasDrawerBoxRef,
  onRuleManagerClick, 
  ruleManagerOpen, 
  onRuleManagerClose, 
  labels, 
  currentProjectId
}) => (
  <div className="bg-gray-100 min-h-screen fixed w-full" key={key}>
    {/* <div className="fixed left-0 top-0 w-full z-10"> */}
      <Header disableNotebookButton={true}/>
    <div className="w-full flex flex-row bg-white">
      { /* Sidebar */ }
      <OutlineSidebar
        paragraphOutline={paragraphOutline}
        outlineOffset={outlineOffset}
        outlineCount={outlineCount}
        currentProjectId={currentProjectId}
        paragraphId={paragraphId}
        notebook={notebook}
        onNextOutlinePageClick={onNextOutlinePageClick}
        onPrevOutlinePageClick={onPrevOutlinePageClick}
      />
      <div className="xl:ml-32 lg:ml-24 bg-white lg:mr-80 h-full">
        { /* Body */ }
        <div className="flex flex-row">
          <div className="ml-4 mr-4">
            <div className="focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500 group block w-full rounded-lg mt-8 relative overflow-auto" style={{ 'height': 'calc(100vh - 320px)'}}>
              <div className="bg-white shadow overflow-hidden sm:rounded-md" ref={lineCanvasDrawerBoxRef}>
                <DrawingCanvas 
                  backgroundImageUrl={paragraph.image_url} 
                />
              </div>
            </div>
          </div>
          
        </div>
      </div>
      <div className="bg-white xl:right-80 lg:right-60 lg:fixed lg:inset-y-0 mt-20 lg:w-72">
        <div className="group block w-full rounded-lg sm:rounded-md h-full relative overflow-auto">
          <div className="bg-white shadow">
            <ul className="divide-y divide-gray-200">
              {annotations.map((annotation, i) => (
                <TextLineReviewLineItem annotation={annotation} value={annotation.value} key={annotation.identifier}/>
              ))}
            </ul>
          </div>
        </div>
      </div>
      {/* <div className="bg-gray-50 xl:w-80 lg:w-60 h-full lg:fixed right-0"> */}
      <div className="bg-gray-50 xl:w-80 lg:w-80 h-screen flex flex-col justify-between lg:fixed right-0">
        <div className="p-5">
          <div className="w-full float-left mb-4">
            <UndoRedo/>
          </div>
          <div className="w-full float-left">
            <h2 className="float-left text-sm font-medium text-gray-500">Rule Tags</h2>
            <a className="float-right text-indigo-700 text-sm hover:underline cursor-pointer" onClick={onRuleManagerClick}>+ Add Rule</a>
          </div>
          <ul className="mt-2 leading-8 float-none">
            {labels.map((label, labelIdx) => (
              <li className="inline" key={`label-${labelIdx}`}>
                <span
                  href="#"
                  className="relative inline-flex items-center rounded-full border border-gray-300 px-3 py-0.5"
                >
                  <div className="absolute flex-shrink-0 flex items-center justify-center">
                    <span className="h-1.5 w-1.5 rounded-full bg-indigo-700" aria-hidden="true" />
                  </div>
                  <div className="ml-3.5 text-sm font-medium text-gray-900">{label['value']}</div>
                </span>{' '}
              </li>
            ))}
          </ul>
          <div className="w-full float-left pt-3 min-w-0 flex-1 md:px-8 lg:px-0 xl:col-span-6 mt-8">
            {Object.keys(reviewStatus).length > 0 && 
              <span>
                <div className="flex items-center justify-between">
                  <div>
                    <span className="mb-2 text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-green-600 bg-green-200">
                      Review status
                    </span>
                  </div>
                  <div className="text-right">
                    <span className="text-xs font-semibold inline-block text-green-600">
                      {`${reviewStatus.checked_for_inheritance} / ${reviewStatus.total}`}
                    </span>
                  </div>
                </div>
                <div className="overflow-hidden h-2 mb-4 text-xs flex rounded bg-green-200">
                  <div style={{ width: `${((reviewStatus.checked_for_inheritance / reviewStatus.total) * 100).toFixed(0)}%` }} className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-green-500"></div>
                </div>
              </span>
            }
          </div>
        </div>
        <div className="p-5 pb-20">

          {prevTextReviewParagraph && prevTextReviewParagraph.identifier &&
            <Link to={`/${currentProjectId}/text-line-review/${notebook.identifier}/${prevTextReviewParagraph.identifier}`}>
              <button
                type="button"
                className="inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                onClick={onPrevClick}
                tabIndex="-1"
              >
                Previous
                
              </button>
            </Link>
          }

          {nextTextReviewParagraph && nextTextReviewParagraph.identifier && 
            <Link to={`/${currentProjectId}/text-line-review/${notebook.identifier}/${nextTextReviewParagraph.identifier}`}>
              <button
                type="button"
                className="inline-flex items-center px-6 py-3 float-right border border-transparent text-base font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                onClick={onSkipClick}
              >
                Skip
                {/* <kbd className="ml-2 text-right inline-flex items-center border border-gray-400 rounded px-2 text-sm font-sans font-medium text-gray-400">
                  ⌘H
                </kbd> */}
              </button>
            </Link>
          }

          {!nextTextReviewParagraph || !nextTextReviewParagraph.identifier && 
            <Link to={`/${currentProjectId}/notebook/${notebook.identifier}`}>
              <button
                type="button"
                className="inline-flex items-center px-6 py-3 float-right border border-transparent text-base font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                onClick={onSkipClick}
              >
                Skip
                {/* <kbd className="ml-2 text-right inline-flex items-center border border-gray-400 rounded px-2 text-sm font-sans font-medium text-gray-400">
                  ⌘H
                </kbd> */}
              </button>
            </Link>
          }

          <button
            className="object-none object-bottom whitespace-nowrap inline-flex items-center justify-center  mt-4 mb-4 px-10 py-3 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-indigo-600 hover:bg-indigo-700"
            onClick={onSubmitClick}>
              Reviewed and Next
              <kbd className="ml-2 text-right inline-flex items-center border border-gray-200 rounded px-2 text-sm font-sans font-medium text-gray-200">
              ⌘K
            </kbd>
          </button>

        </div>
      </div>

    </div>

    {saveError &&
      <Notification/>
    }

    {textLineSaveSuccess &&
      <Redirect to={`/${currentProjectId}/text-line-review/${notebook.identifier}/${nextTextReviewParagraph.identifier}`}/>
    }

    <RuleManager open={ruleManagerOpen} onRuleManagerClose={onRuleManagerClose}/>
  </div>
)

export default TextLineReview