import * as types from './types'
import axios from 'axios'
import _ from 'lodash'

export const fetchNotebooks = () => {
  return (dispatch, getState) => {
    
    /* 
      Todo: 
      - In case project_id exists but no notebooks: show to create new notebook 
      - In case project_id exists but no access: show no access 
      - In case project_id does not exist: redirect to homepage 
    */ 

    const projectId = getState().currentProjectId
    axios({
      method: 'GET', 
      url: `/api/${projectId}/notebooks`
    }).then((resp) => {
      const notebooks = resp.data.notebooks
      dispatch({ 
        type: types.SET_NOTEBOOKS, 
        notebooks: notebooks
      })
    })
  }
}

export const fetchProjectStats = () => {
  return (dispatch, getState) => {
    const projectId = getState().currentProjectId
    axios({
      method: 'GET', 
      url: `/api/${projectId}/project-stats`
    }).then((resp) => {
      const projectStats = resp.data.project_stats
      console.log({ projectStats: projectStats})
      dispatch({
        type: types.SET_PROJECT_STATS, 
        stats: projectStats
      })
    })

  }
}

export const fetchNotebook = (notebookId, page=0) => {
  return (dispatch, getState) => {
    axios({
      method: 'GET', 
      url: `/api/notebook/${notebookId}/${page}`
    }).then((resp) => { 
      let notebook = resp.data.notebook
      let images = resp.data.images
      let totalPages = resp.data.totalPages

      notebook['images'] = images
      notebook['totalPages'] = totalPages

      dispatch({ 
        type: types.SET_SINGLE_NOTEBOOK, 
        notebook: notebook
      })
    }).catch(err => {
      console.log({ msg: 'error while fetching notebook', err: err })
    })
  }
}

export const fetchLatestProjectId = () => {
  return (dispatch, getState) => {
    axios({
      method: 'GET',
      url: `/api/get-latest-project-id`
    }).then((resp) => {
      if(resp.data.projectId !== 0) {
        
        console.log({ projectId: resp.data.projectId })
        dispatch({
          type: types.SET_NO_PROJECTS_FOUND, 
          status: false
        })

        dispatch({
          type: types.SET_CURRENT_PROJECT_ID, 
          projectId: resp.data.projectId
        })

        window.location.replace(`/${resp.data.projectId}`)
      } else {
        dispatch({
          type: types.SET_NO_PROJECTS_FOUND, 
          status: true
        })
      }
    }).catch(err => {
      console.log({ msg: 'error while fetching project', err: err })
    })
  }
}

export const setProjectId = (projectId) => {
  return (dispatch, getState) => {
    dispatch({
      type: types.SET_NO_PROJECTS_FOUND, 
      status: false 
    })

    dispatch({
      type: types.SET_CURRENT_PROJECT_ID, 
      projectId: projectId
    })

    window.location.replace(`/${projectId}`)
  }
}

export const setCurrentProjectId = (projectId) => {
  return (dispatch, getState) => {
    dispatch({
      type: types.SET_CURRENT_PROJECT_ID, 
      projectId: projectId
    })
  }
}

export const fetchParagraphReviewImage = (notebookId, notebookImageId, maxImgWidth) => {
  return (dispatch, getState) => {
    axios({
      method: 'GET',
      url: `/api/notebook-image/${notebookId}/${notebookImageId}/${maxImgWidth}`
    }).then((resp) => {
      const image = resp.data.image
      const rectangles = resp.data.rectangles
      const polygons = resp.data.polygons

      dispatch({
        type: types.SET_PARAGRAPH_REVIEW_IMAGE,
        notebookImage: image
      })

      // dispatch({
      //   type: types.SET_PARAGRAPH_ANNOTATIONS, 
      //   annotations: rectangles.length > 0 ? rectangles : []
      // })
      dispatch({
        type: types.CANVAS_SET_RECTANGLES,
        rectangles: rectangles
      })

      const imgStageSize = {
        w: maxImgWidth, 
        h: image.img_height * maxImgWidth / image.img_width
      }

      dispatch({
        type: 'CANVAS_INIT_POLYGONS', 
        polygons: _.map(polygons, (polygon) => polygon.polygon), 
        imgDim: { w: image.img_width, h: image.img_height },
        canvasDim: { w: imgStageSize.w, h: imgStageSize.h }
      })

      dispatch({
        type: 'CANVAS_INIT_STAGE_SIZE', 
        imgStageSize: imgStageSize
      })
    }).catch(function(err) {
      console.log('fetchParagraphReviewImage error: ', err)
    })
  }
}

export const fetchNotebookImageStatus = (notebookId) => {
  return (dispatch, getState) => {
    axios({
      method: 'GET',
      url: `/api/notebook/image-status/${notebookId}`
    }).then((resp) => {
      const imageStatus = resp.data.image_status
      // console.log({ imageStatus: imageStatus.to_review })
      dispatch({
        type: types.SET_NOTEBOOK_IMAGE_REVIEW_STATUS, 
        status: imageStatus
      })
    }).catch((err) => {
      console.log('fetchNotebookImageStatus error: ', err)
    })
  }
}

export const fetchNotebookImageOutline = (notebookId, notebookImageId, offset, count) => {
  return (dispatch, getState) => {
    axios({
      method: 'GET',
      url: `/api/notebook/image-outline/${notebookId}/${notebookImageId}/${offset}/${count}`
    }).then((resp) => {
      const outline = resp.data.outline
      const hasPrevPage = resp.data.has_prev_page
      const hasNextPage = resp.data.has_next_page
      const totalNotebookImageAmount = resp.data.total_record_amount

      dispatch({
        type: types.SET_NOTEBOOK_IMAGE_OUTLINE, 
        outline: outline,
        hasPrevPage: hasPrevPage,
        hasNextPage: hasNextPage,
        totalNotebookImageAmount: totalNotebookImageAmount
      })

      dispatch({ 
        type: types.SET_PREV_NEXT_PARAGRAPH, 
        outline: outline, 
        notebookImageId: notebookImageId
      })
    }).catch((err) => {
      console.log('fetchNotebookImageOutline error: ', err)
    })
  }
}

// export const setParagraphAnnotations = (annotations) => {
//   return (dispatch, getState) => {

//     const disabledAnnotations = annotations.map(o => 
//       ({ ...o, isActive: false }))

//     dispatch({
//       type: types.SET_PARAGRAPH_ANNOTATIONS,
//       annotations: disabledAnnotations
//     })
//   }
// }

export const deleteImageAnnotation = (identifier) => {
  return (dispatch, getState) => {
    dispatch({
      type: types.DELETE_PARAGRAPH_ANNOTATION,
      identifier: identifier,
      annotations: getState().paragraphAnnotations
    })
  }
}

export const setAnnotationMouseOver = (identifier) => {
  return (dispatch, getState) => {
    dispatch({
      type: types.SET_ANNOTATION_MOUSE_OVER,
      identifier: identifier, 
      annotations: getState().paragraphAnnotations
    })
  }
}

export const setAnnotationMouseOut = (identifier) => {
  return (dispatch, getState) => {
    dispatch({ 
      type: types.SET_ANNOTATION_MOUSE_OUT,
      identifier: identifier,
      annotations: getState().paragraphAnnotations
    })
  }
}

export const toggleAnnotationActivation = (identifier) => {
  return (dispatch, getState) => {
    const annotations = getState().paragraphAnnotations
    
    const toggledAnnotations = annotations.map(o => 
      o.identifier === identifier ? ({ ...o, isActive: !o.isActive }) : ({ ...o, isActive: false }))
    
    dispatch({
      type: types.SET_PARAGRAPH_ANNOTATIONS, 
      annotations: toggledAnnotations
    })  
  }
}

export const resetSaveParagraphStatus = () => {
  return (dispatch, getState) => {
    dispatch({
      type: types.SAVE_PARAGRAPH_RESET, 
      value: false
    })
  }
}

export const initModelRetrain = () => {
  return (dispatch, getState) => {
    const projectId = getState().currentProjectId
    axios({
      method: 'GET',
      url: `/api/project/model/retrain/${projectId}`
    }).then((resp) => {
      dispatch({
        type: types.SET_MODEL_TRAIN_STATUS, 
        inprogress: true,
        success: true
      })
    }).catch((err) => {
      dispatch({
        type: types.SET_MODEL_TRAIN_STATUS, 
        inprogress: true, 
        success: true 
      })
    })
  }
}

export const fetchModelRetrainStatus = () => {
  return (dispatch, getState) => {
    const projectId = getState().currentProjectId
    axios({
      method: 'GET', 
      url: `/api/project/model/status/${projectId}`
    }).then((resp) => {
      console.log('resp: ', resp)
      dispatch({
        type: types.SET_MODEL_TRAIN_STATUS, 
        inprogress: resp.data ? resp.data.training_status === 'training' : false, 
        success: resp.data ? resp.data.training_status !== 'training' : false
      })
    })
  }
}