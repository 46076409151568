export const translatePoint = ({ offsetX, offsetY, scale, positionX, positionY }) => {
  const point = {
    x: (offsetX + -positionX) / scale, 
    y: (offsetY + -positionY) / scale
  }
  return point 
}

export const loadImage = (url, setImage, stageRef) => {
  const img = new window.Image();
  img.onload = () => {
    setImage(img);
    if (stageRef.current) stageRef.current.batchDraw();
  };
  img.src = window.location.protocol + '//' + window.location.hostname + ':' + window.location.port + '/' + url;
};