import './index.css';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";

import NotebookSingleComponent from './containers/notebookSingle'
import NotebookListComponent from './containers/notebookList'
import NotebookCreateComponent from './containers/notebookCreate'
import ProjectCreateComponent from './CreateProject/createProject';
import LoginComponent from './containers/login'
import ParagraphReviewComponent from './ParagraphComponent/paragraphReview'
import TextLineReviewComponent from './TextLineReview/textLineReview'
import SearchAndReplaceComponent from './SearchAndReplace/SearchAndReplace'
import DataExportComponent from './DataExport/dataExport'


function App() {
  const url = new URL(window.location.href);
    if(url.searchParams.get("loginredirect")) {
      return (
        <Router>
          <div className="wrapper">
            <Route exact path="*" component={LoginComponent}/>
          </div>
        </Router>
      )
    } else {
      return (
        <Router>
          <Switch>
            <Route path="/login" component={LoginComponent}/>
            <Route path="/:projectId/notebook/new" component={NotebookCreateComponent}/>
            <Route path="/project/new" component={ProjectCreateComponent}/>
            <Route path="/:projectId/notebook/:notebookId/:page" component={NotebookSingleComponent}/>
            <Route path="/:projectId/notebook/:notebookId" component={NotebookSingleComponent}/>
            <Route path="/:projectId/paragraph-review/:notebookId/:notebookImageId" component={ParagraphReviewComponent}/>
            <Route path="/:projectId/text-line-review/:notebookId/:paragraphId" component={TextLineReviewComponent}/>
            <Route path="/:projectId/data-export/:notebookId" component={DataExportComponent}/>
            <Route path="/:projectId/search-and-replace/:cursor/:offset/:query" component={SearchAndReplaceComponent}/>
            <Route path="/:projectId" component={NotebookListComponent}/>
            <Route path="/" component={NotebookListComponent}/>
          </Switch>
        </Router>
      );
    }
}

export default App;