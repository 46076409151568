import React from 'react';
import { Line, Rect } from 'react-konva';
import { connect, useDispatch, useSelector } from 'react-redux'

const Polygon = ({ polygon, scale, anchorDragMove }) => {
  const dispatch = useDispatch()

  const isMouseActive = useSelector((state) => state.canvasIsMouseActive)
  const drawingMode = useSelector((state) => state.canvasDrawingMode)

  const onPointMouseEnter = (e) => {
    if(isMouseActive) return
    
    if (drawingMode === 'polygon') {
      dispatch({ type: 'CANVAS_POLYGON_IS_MOVING_POINT', isMovingPoint: true })
      e.target.getStage().container().style.cursor = 'grab'
    }
  }

  const onPointMouseLeave = (e) => {
    if(isMouseActive) return
    
    if (drawingMode === 'polygon') {
      dispatch({ type: 'CANVAS_POLYGON_IS_MOVING_POINT', isMovingPoint: false })
      e.target.getStage().container().style.cursor = 'crosshair'
    }
  }
  
  return (
    <>
      <Line
        // key={key}
        points={polygon.points}
        stroke="#4f46e5"
        strokeWidth={1}
        strokeScaleEnabled={false}
        closed={true}
        shadowEnabled={true}
      />
      {polygon.anchors.map((point, index) => (
        <Rect
          key={point.id}
          fill="#4f46e5"
          width={8 / scale}
          height={8 / scale}
          x={point.x - (4 / scale)}
          y={point.y - (4 / scale)}
          draggable={true}
          onDragMove={(e) => anchorDragMove(e, point.id, polygon.id, scale)}
          onMouseOver={(e) => onPointMouseEnter(e)}
          onMouseLeave={(e) => onPointMouseLeave(e)}
          hitStrokeWidth={15}
          strokeScaleEnabled={false}
        />
      ))}
    </>
  )
}

const mapStateToProps = state => {
  return {};
}

export default connect(mapStateToProps)(Polygon);