import React from 'react';
import { Link } from 'react-router-dom';

import Header from '../Header/header';

import { ArrowLeftIcon, ArrowRightIcon } from '@heroicons/react/solid';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const NotebookSingle = ({ 
  images, 
  notebook, 
  currentProjectId, 
  currentProjectName, 
  pageNumbers,
  currentPage, 
  onPageChange, 
  totalPages
}) => (
  <div className="bg-gray-100 min-h-screen">
    <Header notebook={notebook} />

    <div className="flex-1 flex items-stretch">
      <main className="flex-1">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-10">

          {/* Page Title Section */}
          <div className="flex items-center justify-between border-b pb-4 mb-8">
            <h1 className="text-2xl font-extrabold text-gray-900">{notebook.name}</h1>
            <div className="hidden sm:block">
              <Link to={`/${currentProjectId}/line-review/${notebook.identifier}`}>
                <button
                  className="flex items-center justify-center px-3 py-1.5 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-500"
                >
                  Review Model Predictions
                </button>
              </Link>
            </div>
          </div>

          {/* Gallery Section */}
          <section className="mt-8 pb-16 flow-root" aria-labelledby="gallery-heading">
            <div className="inline-block min-w-full py-2 align-middle">
              <table className="min-w-full border-separate border-spacing-0 bg-white">
                <thead>
                  <tr>
                    <th
                      scope="col"
                      className="sticky top-0 z-10 border-b border-gray-300 bg-white/75 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:pl-6 lg:pl-8 lg:table-cell"
                    >
                      Full Image
                    </th>
                    <th
                      scope="col"
                      className="sticky top-0 z-10 hidden border-b border-gray-300 bg-white/75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:table-cell"
                    >
                      Highlighted Paragraph Images
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {images.map((image, imageIdx) => (
                    <tr key={image.identifier}>
                      <td className={classNames(
                          imageIdx !== images.length - 1 ? 'border-b border-gray-200' : '',
                          'whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 lg:pl-8',
                        )}
                      >
                        <Link 
                          key={image.identifier}
                          to={`/${currentProjectId}/paragraph-review/${notebook.identifier}/${image.identifier}`}
                          className={`block w-56 h-40 ring-2 ${image.status !== 'reviewed' ? 'ring-yellow-400' : 'ring-green-400'} rounded-lg overflow-hidden lg:mb-0`}
                        >
                            <img src={`/${image.image_url}`} className="w-full h-full object-cover"/>
                        </Link>
                        <p className="text-sm font-semibold text-gray-900 mt-2 truncate">{image.filename}</p>
                        <p className="text-xs font-medium text-gray-500">
                          <span
                            className={`${image.status !== 'reviewed' ? 'bg-yellow-100 text-yellow-800' : 'bg-green-100 text-green-800'} inline-flex items-center px-2 py-0.5 rounded-full text-xs font-medium`}
                          >
                            {image.status}  
                          </span>
                        </p>
                      </td>
                      <td className={classNames(
                        imageIdx !== images.length - 1 ? 'border-b border-gray-200' : '',
                        'hidden whitespace-nowrap px-3 py-4 text-sm text-gray-500 lg:table-cell align-top',
                      )}>
                        {image.paragraphs.map((paragraph) => (
                          <span className="float-left mr-4">
                            <Link
                              key={paragraph.identifier}
                              to={`/${currentProjectId}/text-line-review/${notebook.identifier}/${paragraph.identifier}`}
                              className={`float-left mr-4 block w-20 h-20 rounded-lg overflow-hidden group ring-2 ${paragraph.status !== 'reviewed' ? 'ring-yellow-400' : 'ring-green-400'}`}
                            >
                              <img
                                src={`/${paragraph.image_url}`}
                                alt=""
                                className="w-full h-full object-cover"
                              />
                            </Link>
                            <p className="text-xs font-medium text-gray-500">
                              <span
                                className={`${paragraph.status !== 'reviewed' ? 'bg-yellow-100 text-yellow-800' : 'bg-green-100 text-green-800'} inline-flex items-center px-2 py-0.5 rounded-full text-xs font-medium`}
                              >
                                Waiting for prediction
                              </span>
                            </p>
                          </span>
                        ))}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </section>

          {/* Pagination */}
          <nav className="flex items-center justify-between border-t border-gray-200 pt-4">
            <div className="-mt-px flex w-0 flex-1">
              <a
                href="#!" 
                style={{ pointerEvents: currentPage === 1 ? 'none' : 'auto', opacity: currentPage === 1 ? 0.5 : 1 }}
                onClick={(e) => { e.preventDefault(); onPageChange(parseInt(currentPage) - 1); }}
                className="inline-flex items-center border-t-2 border-transparent pr-1 pt-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700"
              >
                <ArrowLeftIcon className="mr-3 h-5 w-5 text-gray-400" aria-hidden="true" />
                Previous
              </a>
            </div>
            <div className="hidden md:-mt-px md:flex">
              {pageNumbers.map(page => (
                <a
                  href="#!"
                  key={page}
                  style={{ fontWeight: page === currentPage ? 'bold' : 'normal' }}
                  onClick={(e) => { e.preventDefault(); onPageChange(page); }}
                  className={page === currentPage ? "inline-flex items-center border-t-2 border-indigo-500 px-3 pt-4 text-sm font-medium text-indigo-600" : "inline-flex items-center border-t-2 border-transparent px-3 pt-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700"}
                >
                  {page}
                </a>
              ))}
            </div>

            <div className="-mt-px flex w-0 flex-1 justify-end">
              <a
                href="#!" 
                style={{ pointerEvents: currentPage === totalPages ? 'none' : 'auto', opacity: currentPage === totalPages ? 0.5 : 1 }}
                onClick={(e) => { e.preventDefault(); onPageChange(parseInt(currentPage) + 1); }}
                className="inline-flex items-center border-t-2 border-transparent pl-1 pt-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700"
              >
                Next
                <ArrowRightIcon className="ml-3 h-5 w-5 text-gray-400" aria-hidden="true" />
              </a>
            </div>
          </nav>
        </div>
      </main>
    </div>
  </div>
);

export default NotebookSingle;
