import React, { Component } from 'react';
import * as uploadActions from '../actions/upload'
import * as notebookActions from '../actions/notebook'
import NotebookCreateComponent from '../components/notebookCreate';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';


class NotebookCreate extends Component {

  constructor(props) {
    super(props)

    this.state = {
      disabled: false, 
      paragraphDetection: false
    }

    this.onTitleChange = this.onTitleChange.bind(this)
    this.onSubmitNotebookClick = this.onSubmitNotebookClick.bind(this)
    this.onParagraphDetectionToggle = this.onParagraphDetectionToggle.bind(this)
  }

  componentDidMount() {
    if(this.props.match.params.projectId && this.props.currentProjectId === "") {
      this.props.actions.setCurrentProjectId(this.props.match.params.projectId)
    }
  }

  onTitleChange(e) {
    this.props.actions.setNotebookTitle(e.target.value)
  }

  onParagraphDetectionToggle(e) {
    this.setState({ paragraphDetection: !this.state.paragraphDetection })
  }

  onSubmitNotebookClick() {
    if(this.props.notebookTitle.length > 0 && this.props.acceptedFiles.length > 0){
      this.setState({
        disabled: true
      })
      this.props.actions.uploadNotebook(this.state.paragraphDetection)
    }
  }

  render() {

    const uploadPercentage = this.props.uploadStatus.uploaded === 0 ? 0 : 
      100 - (((this.props.uploadStatus.total - this.props.uploadStatus.uploaded) / this.props.uploadStatus.total) * 100).toFixed(0)

    return (
      <NotebookCreateComponent 
        title={this.props.notebookTitle}
        onTitleChange={this.onTitleChange}
        onSubmitNotebookClick={this.onSubmitNotebookClick}
        disabled={this.state.disabled}
        uploadPercentage={uploadPercentage}
        paragraphDetection={this.state.paragraphDetection}
        onParagraphDetectionToggle={this.onParagraphDetectionToggle}
        currentProjectId={this.props.currentProjectId}
      />
    )
  }
}

function mapStateToProps(state) {
  return {
    notebookTitle: state.notebookTitle, 
    acceptedFiles: state.acceptedFiles, 
    uploadStatus: state.uploadStatus, 
    currentProjectId: state.currentProjectId
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Object.assign({}, uploadActions, notebookActions), dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(NotebookCreate)