import React, { Component } from 'react';
import DataExportComponent from './dataExportComponent';
import * as dataExportActions from './dataExportActions'
import * as notebookActions from '../actions/notebook'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import axios from 'axios'

class DataExport extends Component {

  constructor(props) {
    super(props)

    this.state = {
      disabled: false, 
      data: []
    }

    this.onExportDataClick = this.onExportDataClick.bind(this)
    this.csvLink = React.createRef()
  }

  componentDidMount() {
    if(this.props.match.params.projectId && this.props.currentProjectId === "") {
      this.props.actions.setCurrentProjectId(this.props.match.params.projectId)
    }
  }

  onExportDataClick() {
    // this.props.actions.dataExport({
    //   projectId: this.props.currentProjectId, 
    //   status: 'reviewed', 
    //   notebookId: this.props.match.params.notebookId
    // })
    this.setState({
      disabled: true
    })

    axios({
      method: 'GET', 
      url: `/api/project/export/${this.props.currentProjectId}/reviewed/${this.props.match.params.notebookId}`, 
    }).then((resp) => {
      this.setState({ data: resp.data, disabled: false }, () => {
        this.csvLink.current.link.click()
      })
    })
  }

  render() {
    return (
      <DataExportComponent
        onExportDataClick={this.onExportDataClick}
        disabled={this.state.disabled}
        csvLink={this.csvLink}
        data={this.state.data}
      />
    )
  }

}


function mapStateToProps(state) {
  return {
    currentProjectId: state.currentProjectId
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Object.assign({}, dataExportActions, notebookActions), dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DataExport)
